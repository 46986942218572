export const dashboardMenu = {
  dashboard: {
    pageID: 'DB001',
    id: 'dashboard',
    text: 'Dashboard',
    path: '/panel/dashboard',
    icon: 'Dashboard',
    subMenu: null,
  },
};

export const accountMenu = {
  pageLayout: {
    id: 'account',
    text: 'Accounts',
    path: '/panel/account',
    icon: 'ManageAccounts',
    subMenu: {
      userLists: {
        pageID: 'UL001',
        id: 'user-lists',
        text: 'User Lists',
        path: '/panel/account/user-lists',
        icon: 'ManageAccounts',
        subMenu: null,
      },
      merchantLists: {
        pageID: 'ML001',
        id: 'merchant-lists',
        text: 'Merchant Lists',
        path: '/panel/account/merchant-lists',
        icon: 'ManageAccounts',
        subMenu: null,
      },
    },
  },
};

export const ticketMenu = {
  ticket: {
    pageID: 'TK001',
    id: 'ticket',
    text: 'Ticket Lists',
    path: '/panel/ticket',
    icon: 'ConfirmationNumber',
    subMenu: null,
  },
};

export const adjustmentMenu = {
  adjustment: {
    pageID: 'AD001',
    id: 'adjustment',
    text: 'Adjustment',
    path: '/panel/adjustment',
    icon: 'Tune',
    subMenu: null,
    hide: true,
  },
};

export const monitorMenu = {
  pageLayout: {
    id: 'monitor',
    text: 'Monitor',
    path: '/panel/monitor',
    icon: 'Analytics',
    subMenu: {
      drawJackpotTransaction: {
        pageID: 'MN001',
        id: 'draw-jackpot-transaction',
        text: 'Draw Jackpot Transaction',
        path: '/panel/monitor/draw-jackpot-transaction',
        icon: 'Analytics',
        subMenu: null,
      },
    },
  },
};

export const userProfileMenu = {
  userProfile: {
    pageID: 'UP001',
    id: 'user-profile',
    text: 'User Profile',
    path: '/panel/user-profile',
    icon: 'AccountBox',
    subMenu: null,
    hide: true,
  },
};

export const resultMenu = {
  pageLayout: {
    id: 'result',
    text: 'Results',
    path: '/panel/result',
    icon: 'AutoGraph',
    subMenu: {
      drawResultMaintenance: {
        pageID: 'DR002',
        id: 'draw-result-maintenance',
        text: 'Draw Result Maintenance',
        path: '/panel/result/maintenance',
        icon: 'AutoGraph',
        subMenu: null,
      },
      drawResult: {
        pageID: 'DR001',
        id: 'draw-result',
        text: 'Draw Result',
        path: '/panel/result/draw-result',
        icon: 'AutoGraph',
        subMenu: null,
        hide: true,
      },
      interfaceDrawResult: {
        pageID: 'IDR001',
        id: 'interface-draw-result',
        text: 'Interface Draw Result',
        path: '/panel/result/interface-draw-result',
        icon: 'AutoGraph',
        hide: true,
      },
    },
  },
};

export const reportMenu = {
  pageLayout: {
    id: 'report',
    text: 'Reports',
    path: '/panel/report',
    icon: 'ReceiptLong',
    subMenu: {
      strikeReport: {
        pageID: 'SR001',
        id: 'strike-report',
        text: 'Strike Report',
        path: '/panel/report/strike-report',
        icon: 'ReceiptLong',
      },
      winLossReport: {
        pageID: 'WL001',
        id: 'win-loss-report',
        text: 'Win Loss Report',
        path: '/panel/report/win-loss-report',
        icon: 'ReceiptLong',
      },
      betNumberReport: {
        pageID: 'BN001',
        id: 'bet-number-report',
        text: 'Bet Number Sales Report',
        path: '/panel/report/bet-number-report',
        icon: 'ReceiptLong',
      },
      jackpotReport: {
        pageID: 'JP001',
        id: 'jackpot-report',
        text: 'Jackpot Strike Report',
        path: '/panel/report/jackpot-report',
        icon: 'ReceiptLong',
      },
      exportReport: {
        pageID: 'EX001',
        id: 'export-report',
        text: 'Export Reports',
        path: '/panel/report/export-report',
        icon: 'ReceiptLong',
      },
      drawPayoutSummaryReport: {
        pageID: 'DPS001',
        id: 'draw-payout-summary-report',
        text: 'Draw Payout Summary Report',
        path: '/panel/report/draw-payout-summary-report',
        icon: 'ReceiptLong',
      },
    },
  },
};

export const configurationsMenu = {
  pageLayout: {
    id: 'config',
    text: 'Configurations',
    path: '/panel/configurations',
    icon: 'Settings',
    subMenu: {
      limitManagement: {
        pageID: 'LM001',
        id: 'limit-management',
        text: 'Limits Management',
        path: '/panel/configurations/limit-management',
        icon: 'Settings',
      },
      jackpotMaintenance: {
        pageID: 'JM001',
        id: 'jackpot-maintenance',
        text: 'Jackpot Maintenance',
        path: '/panel/configurations/jackpot-maintenance',
        icon: 'Settings',
      },
      pricingPackage: {
        pageID: 'PP001',
        id: 'pricing-package',
        text: 'Pricing Package',
        path: '/panel/configurations/pricing-package',
        icon: 'Settings',
      },
      drawSchedule: {
        pageID: 'DS001',
        id: 'draw-schedule',
        text: 'Draw Schedule',
        path: '/panel/configurations/draw-schedule',
        icon: 'Settings',
        hide: true,
      },
      gameSetting: {
        pageID: 'GS001',
        id: 'game-setting',
        text: 'Game Setting',
        path: '/panel/configurations/game-setting',
        icon: 'Settings',
        hide: true,
      },
      siteSetting: {
        pageID: 'SS001',
        id: 'site-setting',
        text: 'Site Setting',
        path: '/panel/configurations/site-setting',
        icon: 'Build',
      },
      contentManagement: {
        pageID: 'CM001',
        id: 'content-management',
        text: 'Content Management',
        path: '/panel/configurations/content-management',
        icon: 'Settings',
      },
    },
  },
};

export const systemSettingMenu = {
  pageLayout: {
    id: 'systemSetting',
    text: 'System Setting',
    path: '/panel/system-setting',
    icon: 'Build',
    subMenu: {
      systemParameter: {
        pageID: 'SP001',
        id: 'system-parameter',
        text: 'System Parameter',
        path: '/panel/system-setting/system-parameter',
        icon: 'Build',
      },
      permission: {
        pageID: 'PM001',
        id: 'permission',
        text: 'Permission',
        path: '/panel/system-setting/permission',
        icon: 'Build',
      },
      announcement: {
        pageID: 'AN001',
        id: 'announcement',
        text: 'Announcement',
        path: '/panel/system-setting/announcement',
        icon: 'Build',
        hide: true,
      },
      currency: {
        pageID: 'CU001',
        id: 'currency',
        text: 'Currency',
        path: '/panel/system-setting/currency',
        icon: 'Build',
        hide: true,
      },
      drawSettlementConfig: {
        pageID: 'DSC001',
        id: 'draw-settlement-config',
        text: 'Draw Settlement Config',
        path: '/panel/system-setting/draw-settlement-config',
        icon: 'Build',
      },
      provisionHistory: {
        pageID: 'PHC001',
        id: 'provision-history',
        text: 'Provision History',
        path: '/panel/system-setting/provision-history',
        icon: 'Build',
      },
    },
  },
};

export const transactionHistoryMenu = {
  pageLayout: {
    pageID: 'T001',
    id: 'transactionHistory',
    text: 'Transaction History',
    path: '/panel/transactionHistory',
    icon: 'CreditCard',
    subMenu: null,
  },
};

export const dynamicMenu = {
  ticket: {
    pageID: 'TK002',
    id: 'ticket-details',
    text: 'Ticket Details',
    path: `${ticketMenu.ticket.path}/:id`,
    icon: 'ConfirmationNumber',
  },
  strikeReportTicket: {
    pageID: 'TK003',
    id: 'ticket-details-strike-report',
    text: 'Ticket Details',
    path: `${reportMenu.pageLayout?.path}/:type/:id`,
    icon: 'ConfirmationNumber',
  },
  jackpotReportTicket: {
    pageID: 'TK004',
    id: 'ticket-details-jackpot-report',
    text: 'Ticket Details',
    path: `${reportMenu.pageLayout?.path}/jackpot-report/:id`,
    icon: 'ConfirmationNumber',
  },
  unit: {
    pageID: 'UN001',
    id: 'unit-details',
    text: 'Unit Details',
    path: `${reportMenu.pageLayout?.path}/jackpot-report/unit/:id`,
  },
};

export const demoPagesMenu = {
  auth: {
    id: 'auth',
    text: 'Auth Pages',
    icon: 'Extension',
  },
  login: {
    id: 'login',
    text: 'Login',
    path: 'panel/auth-pages/login',
    icon: 'Login',
  },
  signUp: {
    id: 'signUp',
    text: 'Sign Up',
    path: 'panel/auth-pages/sign-up',
    icon: 'PersonAdd',
  },
  page404: {
    id: 'Page404',
    text: '404 Page',
    path: 'panel/auth-pages/404',
    icon: 'ReportGmailerrorred',
  },
  page403: {
    id: 'Page403',
    text: '403 Page',
    path: 'panel/auth-pages/403',
    icon: 'ReportGmailerrorred',
  },
};

export const pageLayoutTypesPagesMenu = {
  layoutTypes: {
    id: 'layoutTypes',
    text: 'Page Layout Types',
  },
  blank: {
    id: 'blank',
    text: 'Blank',
    path: 'panel/page-layouts/blank',
    icon: 'check_box_outline_blank ',
  },
  pageLayout: {
    id: 'pageLayout',
    text: 'Page Layout',
    path: 'panel/page-layouts',
    icon: 'BackupTable',
    subMenu: {
      headerAndSubheader: {
        id: 'headerAndSubheader',
        text: 'Header & Subheader',
        path: 'panel/page-layouts/header-and-subheader',
        icon: 'ViewAgenda',
      },
      onlyHeader: {
        id: 'onlyHeader',
        text: 'Only Header',
        path: 'panel/page-layouts/only-header',
        icon: 'ViewStream',
      },
      onlySubheader: {
        id: 'onlySubheader',
        text: 'Only Subheader',
        path: 'panel/page-layouts/only-subheader',
        icon: 'ViewStream',
      },
      onlyContent: {
        id: 'onlyContent',
        text: 'Only Content',
        path: 'panel/page-layouts/only-content',
        icon: 'WebAsset',
      },
    },
  },
  asideTypes: {
    id: 'asideTypes',
    text: 'Aside Types',
    path: 'panel/aside-types',
    icon: 'Vertical Split',
    subMenu: {
      defaultAside: {
        id: 'defaultAside',
        text: 'Default Aside',
        path: 'panel/aside-types/default-aside',
        icon: 'ViewQuilt',
      },
      minimizeAside: {
        id: 'minimizeAside',
        text: 'Minimize Aside',
        path: 'panel/aside-types/minimize-aside',
        icon: 'View Compact',
      },
    },
  },
};
