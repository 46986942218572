import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Button from 'app/components/bootstrap/Button';
import Icon from 'app/components/icon/Icon';
import { demoPagesMenu } from 'app/menu';
import React, { Suspense, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Page from '../Page/Page';
import PageWrapper from '../PageWrapper/PageWrapper';
import SubHeader from '../SubHeader/SubHeader';
import ContentRoutes from './ContentRoutes';
import { useTranslation } from 'react-i18next';

const LOADING = (
  <PageWrapper>
    <SubHeader>
      <div />
    </SubHeader>
    <Page>
      <div className="row h-100">
        <div className="col-lg-6">
          <Card stretch>
            <CardBody>
              <div />
            </CardBody>
          </Card>
        </div>
        <div className="col-lg-6">
          <Card stretch="semi">
            <CardBody>
              <div />
            </CardBody>
          </Card>
          <Card stretch="semi">
            <CardBody>
              <div />
            </CardBody>
          </Card>
        </div>
      </div>
    </Page>
  </PageWrapper>
);

const Content = () => {
  const [reloadOpened, { open: reloadOpen, close: reloadClose }] = useDisclosure(false);
  const [reloginOpened, { open: reloginOpen, close: reloginClose }] = useDisclosure(false);
  const curlocation = useLocation();
  const { t, i18n } = useTranslation();
  const isLogoutModalOpen = sessionStorage.getItem('isLogoutModalOpen');

  useEffect(() => {
    const logoutChannel = new BroadcastChannel('logout_channel');
    logoutChannel.addEventListener('message', handleLogoutMessage);

    return () => {
      logoutChannel.removeEventListener('message', handleLogoutMessage);
    };
  }, []);

  const handleLogoutMessage = useCallback(
    event => {
      if (event.data === 'logout' && curlocation.pathname !== '/' + demoPagesMenu.login.path) {
        reloadOpen();
      } else if (event.data === 'relogin' && curlocation.pathname !== '/' + demoPagesMenu.login.path) {
        sessionStorage.setItem('multipleLogin', 'true');
        reloginOpen();
      }
    },
    [curlocation]
  );

  const handleModalLogoutConfirm = useCallback(() => {
    sessionStorage.setItem('inactiveLogout', 'true');
    location.replace(`/${demoPagesMenu.login.path}`);
    reloadClose();
  }, [curlocation]);

  const handleModalReloginConfirm = useCallback(() => {
    location.replace(`/${demoPagesMenu.login.path}`);
    reloginClose();
  }, [curlocation]);

  return (
    <main className="content">
      <Suspense fallback={LOADING}>
        <ContentRoutes />
        {isLogoutModalOpen === 'false' && (
          <Modal
            closeOnEscape={false}
            withCloseButton={false}
            closeOnClickOutside={false}
            styles={theme => ({
              content: {
                borderTop: '4px solid',
                borderTopColor: theme.colors.red[5],
              },
            })}
            opened={reloadOpened}
            onClose={reloadClose}
            title={t('signInAgain')}
          >
            <div className="d-flex gap-3 mb-4 align-items-center">
              <Icon icon="Error" color="danger" size="5x" />
              <p className="m-0">{t('youSignOutReloadLogin')}</p>
            </div>
            <div className="d-flex gap-4 w-100 justify-content-end">
              <Button icon="Cancel" color="danger" className="w-30" onClick={handleModalLogoutConfirm}>
                {t('reload')}
              </Button>
            </div>
          </Modal>
        )}
        <Modal
          closeOnEscape={false}
          withCloseButton={false}
          closeOnClickOutside={false}
          styles={theme => ({
            content: {
              borderTop: '4px solid',
              borderTopColor: theme.colors.red[5],
            },
          })}
          opened={reloginOpened}
          onClose={reloginClose}
          title={t('confirmation')}
        >
          <div className="d-flex gap-3 mb-4 align-items-center">
            <Icon icon="Error" color="danger" size="5x" />
            <p className="m-0">{t('youLogoutAnotherSignin')}</p>
          </div>
          <div className="d-flex gap-4 w-100 justify-content-end">
            <Button icon="Cancel" color="danger" className="w-30" onClick={handleModalReloginConfirm}>
              OK
            </Button>
          </div>
        </Modal>
      </Suspense>
    </main>
  );
};

export default Content;
