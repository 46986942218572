import { ColorScheme, ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { TourProvider } from '@reactour/tour';
import { ENV_CONSTANTS } from 'app/common/envConstants';
import 'dayjs/locale/en-sg';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { ToastProvider } from 'react-toast-notifications';
import { useFullscreen } from 'react-use';
import COLORS from '../common/data/enumColors';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import ThemeContext from '../contexts/themeContext';
import { getOS } from '../helpers/helpers';
import useDarkMode from '../hooks/useDarkMode';
import AsideRoutes from '../layout/Aside/AsideRoutes';
import Portal from '../layout/Portal/Portal';
import Wrapper from '../layout/Wrapper/Wrapper';
import steps, { styles } from '../steps';

const App = () => {
  getOS();

  // Dark mode
  const { themeStatus, darkModeStatus } = useDarkMode();
  const theme = {
    theme: themeStatus,
    primary: COLORS.PRIMARY.code,
    secondary: COLORS.SECONDARY.code,
    success: COLORS.SUCCESS.code,
    info: COLORS.INFO.code,
    warning: COLORS.WARNING.code,
    danger: COLORS.DANGER.code,
    dark: COLORS.DARK.code,
    light: COLORS.LIGHT.code,
  };

  const darkTheme = darkModeStatus ? 'dark' : 'light';

  const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
  const toggleColorScheme = (value?: ColorScheme) => setColorScheme(value || (colorScheme === darkTheme ? 'light' : 'dark'));

  useEffect(() => {
    if (darkModeStatus) {
      document.documentElement.setAttribute('theme', 'dark');
    }
    return () => {
      document.documentElement.removeAttribute('theme');
    };
  }, [darkModeStatus]);

  // Fullscreen
  // @ts-ignore
  const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
  const ref = useRef(null);
  useFullscreen(ref, fullScreenStatus, {
    onClose: () => setFullScreenStatus(false),
  });

  // Modern design
  useLayoutEffect(() => {
    if (ENV_CONSTANTS.REACT_APP_MODERN_DESGIN === true) {
      document.body.classList.add('modern-design');
    } else {
      document.body.classList.remove('modern-design');
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <ToastProvider components={{ ToastContainer, Toast }}>
        <TourProvider steps={steps} styles={styles} showNavigation={false} showBadge={false}>
          <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <MantineProvider
              theme={{
                fontFamily: 'Roboto, sans-serif',
                colorScheme: darkTheme,
                colors: {
                  darkViolet: [
                    '#D7D5DC',
                    '#C1BECB',
                    '#AEA8BD',
                    '#9B92B3',
                    '#897CAB',
                    '#7765A7',
                    '#6750A4',
                    '#60508C',
                    '#594D79',
                    '#524969',
                  ],
                },
                components: {
                  Modal: {
                    defaultProps: {
                      centered: 'true',
                      size: 'lg',
                      zIndex: 8888,
                      closeOnClickOutside: false,
                    },
                    styles: theme => ({
                      content: {
                        borderRadius: theme.radius.sm,
                        padding: theme.spacing.sm,
                      },
                      header: {
                        // marginBottom: theme.spacing.md,
                        zIndex: 9999,
                      },
                      title: {
                        fontSize: theme.fontSizes.xl,
                        fontWeight: 'bold',
                      },
                    }),
                  },
                  InputWrapper: {
                    styles: theme => ({
                      label: {
                        marginBottom: theme.spacing.xs,
                      },
                    }),
                  },
                  Input: {
                    defaultProps: {
                      size: 'md',
                    },
                    styles: theme => ({
                      input: {
                        backgroundColor: theme.colorScheme === 'dark' ? '#212529' : '#ECF0FF',
                        borderColor: theme.colorScheme === 'dark' ? '#212529' : '#ECF0FF',
                        borderRadius: theme.radius.md,
                        size: 'md',
                        '&:focus': {
                          borderColor: theme.colorScheme === 'dark' ? theme.colors.blue[4] : theme.colors.blue[7],
                        },
                        '&:disabled': {
                          color: '#000000',
                          fontWeight: 'bold',
                        },
                      },
                    }),
                  },
                  TextInput: {
                    defaultProps: {
                      size: 'md',
                    },
                  },
                  PasswordInput: {
                    defaultProps: {
                      size: 'md',
                    },
                  },
                  NumberInput: {
                    defaultProps: {
                      size: 'md',
                    },
                  },
                  DateInput: {
                    defaultProps: {
                      size: 'md',
                      maxDate: new Date(),
                    },
                    styles: theme => ({
                      day: {
                        backgroundColor: theme.colorScheme === 'dark' ? '#212529' : theme.white,
                      },
                      calendar: {
                        position: 'relative',
                        zIndex: 999999999999999,
                      },
                    }),
                  },
                  DateTimePicker: {
                    defaultProps: {
                      size: 'md',
                    },
                  },
                  DatePickerInput: {
                    defaultProps: {
                      size: 'md',
                    },
                  },
                  PinInput: {
                    styles: theme => ({
                      input: {
                        borderRadius: theme.radius.sm,
                        backgroundColor: theme.colorScheme === 'dark' ? '#212529' : '#ECF0FF',
                        borderColor: theme.colorScheme === 'dark' ? '#212529' : '#ECF0FF',
                        width: '100%',
                      },
                    }),
                  },
                  Select: {
                    defaultProps: {
                      size: 'md',
                    },
                    styles: theme => ({
                      input: {
                        backgroundColor: theme.colorScheme === 'dark' ? '#212529' : '#ECF0FF',
                        borderColor: theme.colorScheme === 'dark' ? '#212529' : '#ECF0FF',
                        borderRadius: theme.radius.md,
                        '&:focus': {
                          borderColor: theme.colors.blue[4],
                        },
                      },
                      dropdown: {
                        borderRadius: theme.radius.md,
                      },
                      item: {
                        borderRadius: theme.radius.md,
                      },
                    }),
                  },
                },
              }}
            >
              {/* locale: 'th', firstDayOfWeek: 0, weekendDays: [0] */}
              <DatesProvider settings={{}}>
                <div
                  ref={ref}
                  className="app"
                  style={{
                    backgroundColor: fullScreenStatus ? 'var(--bs-body-bg)' : undefined,
                    zIndex: fullScreenStatus ? 1 : undefined,
                    overflow: fullScreenStatus ? 'scroll' : undefined,
                  }}
                >
                  <AsideRoutes />
                  <Wrapper />
                </div>
                <Portal id="portal-notification">
                  <ReactNotifications />
                </Portal>
              </DatesProvider>
            </MantineProvider>
          </ColorSchemeProvider>
        </TourProvider>
      </ToastProvider>
    </ThemeProvider>
  );
};

export default App;
