import React from 'react';
import Footer from '../../../layout/Footer/Footer';
import Popovers from '../../../components/bootstrap/Popovers';

const DefaultFooter = () => {
  return (
    <></>
    // <Footer>
    //   <div className="container-fluid" style={{ zIndex: -1 }}>
    //     <div className="row">
    //       <p className="m-0 d-flex justify-content-center">© 2023 UPowerBall. All rights reserved.</p>
    //     </div>
    //   </div>
    // </Footer>
  );
};

export default DefaultFooter;
