import useServerTime from 'app/hooks/useServerTime';
import React from 'react';
import { useTranslation } from 'react-i18next';

const HeaderServerTime = () => {
  const serverTime = useServerTime();
  const { t } = useTranslation();

  return (
    <div className="col-auto d-flex flex-column w-20">
      {serverTime && <p className="m-0 fw-bold text-primary fs-5 text-center"> {serverTime?.toLocaleTimeString()}</p>}
      <p className="m-0">{t('serverTime')}</p>
    </div>
  );
};

export default HeaderServerTime;
