import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'app/common/apiConstants';
import { getServerTime } from 'app/services/baseApi';
import { useEffect, useState } from 'react';

const useServerTime = () => {
  const { SERVER_TIME } = QUERY_KEYS;
  const serverTimeQuery = useQuery([SERVER_TIME], getServerTime);

  const [serverTime, setServerTime] = useState<Date | null>(null);

  const extractDateFromServerTime = serverDateTime => {
    const date = new Date(serverDateTime);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed.
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (serverTimeQuery.isSuccess && serverTimeQuery.data?.content?.serverDateTime) {
      const serverDateTime = new Date(serverTimeQuery.data.content.serverDateTime);
      setServerTime(serverDateTime);
      localStorage.setItem('todayDate', extractDateFromServerTime(serverDateTime));
    }
  }, [serverTimeQuery.isSuccess]);

  useEffect(() => {
    const interval = setInterval(() => {
      setServerTime(prevTime => {
        if (prevTime) {
          const currentTime = new Date(prevTime);
          currentTime.setSeconds(currentTime.getSeconds() + 1);
          return currentTime;
        }
        return null;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return serverTime;
};

export default useServerTime;
