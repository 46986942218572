export interface ILang {
	[key: string]: {
		text: string;
		lng: 'th' | 'en' ;
		icon: string;
	};
}

const LANG: ILang = {
	TH: {
		text: 'Thai',
		lng: 'th',
		icon: 'CustomThailand',
	},
	EN: {
		text: 'English',
		lng: 'en',
		icon: 'CustomUsa',
	},
};

export const getLangWithKey = (key: ILang['key']['lng']): ILang['key'] => {
	// @ts-ignore
	return LANG[Object.keys(LANG).filter((f) => key.includes(LANG[f].lng))];
};

export default LANG;
