import React from 'react';
import HeaderLogout from './HeaderLogout';
import HeaderServerTime from './HeaderServerTime';
// import HeaderThemeToggle from './HeaderThemeToggle';
import HeaderUser from './HeaderUser';
import HeaderLanguage from './HeaderLanguage';

const HeaderRightContent = () => {
  return (
    <div className="row g-3 align-items-center">
      <HeaderLanguage />
      <HeaderUser />
      <HeaderServerTime />
      {/* <HeaderThemeToggle /> */}
      <HeaderLogout />
    </div>
  );
};

export default HeaderRightContent;
