export const LOCAL_STORAGE_CONSTANTS = {
  AUTH_USERNAME: 'facit_authUsername' as 'facit_authUsername',
  ACCESS_TOKEN: 'accessToken' as 'accessToken',
  PERMISSIONS: 'permissions' as 'permissions',
  FIRST_TIME_LOGIN: 'firstTimeLogin' as 'firstTimeLogin',
  DATE_RANGE_FILTER: 'dateRangeFilter' as 'dateRangeFilter',
  ROLES: 'roles' as 'roles',
  HAVE_JACKPOT: 'haveJackpot' as 'haveJackpot',
  BRAND_NAME: 'brandName' as 'brandName',
  LOGO_PACKAGE: 'logoPackage' as 'logoPackage',
  BUCKET_NAME: 'bucketName' as 'bucketName',
};
