import { LOCAL_STORAGE_CONSTANTS } from 'app/common/localStorageConstants';
import showNotification from 'app/components/extras/showNotification';
import { demoPagesMenu } from 'app/menu';
import axios, { AxiosInstance } from 'axios';
import { ApiRequestConfig } from '../type/api-type';
import { useTranslation, withTranslation } from 'react-i18next';
import i18n from 'app/i18n';

let isHandlerEnabled = true;
let isLogoutInProgress = false;

// Define a global flag to indicate whether a token refresh is already in progress
let isRefreshingToken = false;

// Create a queue to hold the API requests
const requestQueue = [];
const requestQueuePromises = []; // Track the associated promises for each request

// Function to process the request queue
const processQueue = async () => {
  // Set isRefreshingToken flag to true to prevent further API requests
  isRefreshingToken = true;
  try {
    // Call the refresh token API to get a new access token
    const response = await serverAxiosInstance.post('/auth/refresh', {});
    // Update the access token in local storage
    localStorage.setItem(LOCAL_STORAGE_CONSTANTS.ACCESS_TOKEN, response.data.data.content.accessToken);
    // Set the new access token in the Authorization header
    serverAxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.data.content.accessToken}`;
    // Process each request in the queue
    while (requestQueue.length > 0) {
      const config = requestQueue.shift();
      const { resolve, reject } = requestQueuePromises.shift();
      try {
        // Make the request with the updated access token
        const response = await serverAxiosInstance(config);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    }
  } catch (refreshTokenError) {
    // Handle the error when the refresh token API fails
    // Redirect the user to the login page, or show an error message
  } finally {
    // Set isRefreshingToken flag to false to allow new API requests
    isRefreshingToken = false;
  }
};

export const serverAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add a request interceptor to add the access token to the headers
serverAxiosInstance.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.ACCESS_TOKEN);
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

serverAxiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    //? Token Expired
    if (error.response.data.code === '4004' && !originalRequest._retry) {
      originalRequest._retry = true;
      // Return a new promise to indicate that the request will be retried
      const retryPromise = new Promise((resolve, reject) => {
        requestQueuePromises.push({ resolve, reject });
      });
      // Add the failed request to the queue
      requestQueue.push(originalRequest);
      // If there is no ongoing token refresh, start processing the queue
      if (!isRefreshingToken) {
        processQueue();
      }
      return retryPromise;

      //? Token Invalid
    } else if (error.response.data.code === '4001' && isHandlerEnabled && !isLogoutInProgress) {
      // Update this condition
      isLogoutInProgress = true; // Set the flag to true
      isHandlerEnabled = false;
      try {
        // Call the logout API
        await serverAxiosInstance.post('/auth/logout', {});
        // Clear the access token from local storage
        localStorage.clear();
        sessionStorage.clear();
        // showNotification('Logout notice', 'This account has been logged in by another device.', 'default', true);
        const t = i18n.getFixedT(null, 'translation');
        showNotification(t('logoutNotice'), t('duplicateAccLogin'), 'default', true);
        // Redirect the user to the login page
        setTimeout(() => {
          window.location.href = `/${demoPagesMenu.login.path}`;
        }, 3000);
      } catch (logoutError) {
        localStorage.clear();
        sessionStorage.clear();
        // showNotification('Logout notice', 'This account has been logged in by another device.', 'default', true);
        const t = i18n.getFixedT(null, 'translation');
        showNotification(t('logoutNotice'), t('duplicateAccLogin'), 'default', true);
        // Handle the error when the logout API fails
        // Redirect the user to the login page, or show an error message
        setTimeout(() => {
          window.location.href = `/${demoPagesMenu.login.path}`;
        }, 3000);
      } finally {
        isLogoutInProgress = false; // Set the flag back to false
      }
    } else if (error.response.data.code === '9000') {
      window.location.href = `/${demoPagesMenu.page403.path}`;
    }
    // Pass the error to the component
    return Promise.reject(error);
  }
);

const api = (axios: AxiosInstance) => {
  return {
    get: (url: string, config: ApiRequestConfig = {}) => axios.get(url, config),
    delete: (url: string, config: ApiRequestConfig = {}) => axios.delete(url, config),
    post: (url: string, body: unknown, config: ApiRequestConfig = {}) => axios.post(url, body, config),
    put: (url: string, body: unknown, config: ApiRequestConfig = {}) => axios.put(url, body, config),
    patch: (url: string, body: unknown, config: ApiRequestConfig = {}) => axios.patch(url, body, config),
  };
};

export const serverApi = api(serverAxiosInstance);
