import { dynamicMenu } from 'app/menu';
import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import contents from '../../routes/contentRoutes';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));
const DASHBOARD_PAGE = lazy(() => import('../../pages/presentation/general/DashboardPage'));
const TICKET_DETAILS_PAGE = lazy(() => import('../../pages/presentation/dynamic/TicketDetailsPage'));
const UNIT_DETAILS_PAGE = lazy(() => import('../../pages/presentation/dynamic/UnitDetailsPage'));

const ContentRoutes = () => {
  return (
    <Routes>
      {contents.map(page => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Route key={page.path} {...page} />
      ))}
      <Route path={dynamicMenu.ticket.path} element={<TICKET_DETAILS_PAGE />} />
      <Route path={dynamicMenu.strikeReportTicket.path} element={<TICKET_DETAILS_PAGE />} />
      <Route path={dynamicMenu.unit.path} element={<UNIT_DETAILS_PAGE />} />
      <Route path="*" element={<DASHBOARD_PAGE />} />
    </Routes>
  );
};

export default ContentRoutes;
