import { ENV_CONSTANTS } from 'app/common/envConstants';
import { LOCAL_STORAGE_CONSTANTS } from 'app/common/localStorageConstants';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { ReactElement, forwardRef, useEffect, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { dashboardMenu, demoPagesMenu } from '../../menu';
import { IPageProps } from '../Page/Page';
import { ISubHeaderProps } from '../SubHeader/SubHeader';
import useGetLogo from 'app/hooks/useGetLogo';

interface IPageWrapperProps {
  isProtected?: boolean;
  title?: string;
  description?: string;
  children: ReactElement<ISubHeaderProps>[] | ReactElement<IPageProps> | ReactElement<IPageProps>[];
  className?: string;
}
const PageWrapper = forwardRef<HTMLDivElement, IPageWrapperProps>(({ isProtected, title, description, className, children }, ref) => {
  useLayoutEffect(() => {
    // @ts-ignore
    document.getElementsByTagName('TITLE')[0].text = `${title ? `${title} | ` : ''}${ENV_CONSTANTS.REACT_APP_SITE_NAME}`;
    // @ts-ignore
    document?.querySelector('meta[name="description"]').setAttribute('content', description || ENV_CONSTANTS.REACT_APP_META_DESC || '');
  });

  const curlocation = useLocation();
  const navigate = useNavigate();
  const favicon = useGetLogo('favicon');

  useEffect(() => {
    if (
      isProtected &&
      localStorage.getItem(LOCAL_STORAGE_CONSTANTS.ACCESS_TOKEN) === null &&
      localStorage.getItem(LOCAL_STORAGE_CONSTANTS.PERMISSIONS) === null
    ) {
      navigate(`/${demoPagesMenu.login.path}`);
    } else if (localStorage.getItem(LOCAL_STORAGE_CONSTANTS.FIRST_TIME_LOGIN) === 'true') {
      localStorage.clear();
      navigate(`/${demoPagesMenu.login.path}`);
    } else if (
      curlocation.pathname === '/panel/auth-pages/login' &&
      localStorage.getItem(LOCAL_STORAGE_CONSTANTS.ACCESS_TOKEN) !== null &&
      localStorage.getItem(LOCAL_STORAGE_CONSTANTS.AUTH_USERNAME) !== null &&
      sessionStorage.getItem('multipleLogin') !== 'true' &&
      sessionStorage.getItem('inactiveLogout') !== 'true'
    ) {
      navigate(`${dashboardMenu.dashboard.path}`);
    } else if (sessionStorage.getItem('multipleLogin') === 'true') {
      Promise.resolve()
        .then(() => {
          navigate(`/${demoPagesMenu.login.path}`);
        })
        .then(() => {
          sessionStorage.clear();
        });
    } else if (sessionStorage.getItem('inactiveLogout') === 'true') {
      Promise.resolve()
        .then(() => {
          navigate(`/${demoPagesMenu.login.path}`);
        })
        .then(() => {
          sessionStorage.clear();
        });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const link = (document.querySelector("link[rel='icon']") as HTMLLinkElement) || document.createElement('link');
    link.type = 'image/png';
    link.rel = 'icon';
    link.href = favicon;

    document.head.appendChild(link);
  });

  return (
    <div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
      {children}
    </div>
  );
});
PageWrapper.displayName = 'PageWrapper';
PageWrapper.propTypes = {
  isProtected: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  // @ts-ignore
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
PageWrapper.defaultProps = {
  isProtected: true,
  title: undefined,
  description: undefined,
  className: undefined,
};

export default PageWrapper;
