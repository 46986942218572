import { ENV_CONSTANTS } from 'app/common/envConstants';
import HeaderRightContent from 'app/components/header/HeaderRightContent';
// import Search from 'app/components/Search';
import useDeviceScreen from 'app/hooks/useDeviceScreen';
import Navigation from 'app/layout/Navigation/Navigation';
import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';

const DashboardHeader = ({ menu }: { menu?: {} }) => {
  const { width } = useDeviceScreen();
  const { i18n } = useTranslation();

  /**
   * Language attribute
   */
  useLayoutEffect(() => {
    document.documentElement.setAttribute('lang', i18n.language);
  });

  return (
    <Header>
      <HeaderLeft>
        <Navigation
          menu={{ ...menu }}
          id="header-top-menu"
          horizontal={!!width && width >= Number(ENV_CONSTANTS.REACT_APP_MOBILE_BREAKPOINT_SIZE)}
        />
        {/* <Search /> */}
      </HeaderLeft>

      <HeaderRight>
        <HeaderRightContent />
      </HeaderRight>
    </Header>
  );
};

export default DashboardHeader;
