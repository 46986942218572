import HeaderRightContent from 'app/components/header/HeaderRightContent';
import { demoPagesMenu } from 'app/menu';
import { default as React, useEffect, useState } from 'react';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import { LOCAL_STORAGE_CONSTANTS } from 'app/common/localStorageConstants';

interface IBreadcrumbItemProps {
  title: string;
  to: string;
}

type ArrayItem = IBreadcrumbItemProps;

interface IDefaultHeaderProps {
  breadcrumbList: Array<ArrayItem>;
}
const DefaultHeader = ({ breadcrumbList }: IDefaultHeaderProps) => {
  const [loginPermissions, setLoginPermissions] = useState<string>('');
  const [isManualChgPermission, setIsManualChgPermission] = useState<boolean>(true);

  const [loginRoles, setLoginRoles] = useState<string>('');

  useEffect(() => {
    setLoginPermissions(localStorage.getItem(LOCAL_STORAGE_CONSTANTS.PERMISSIONS));
    setLoginRoles(localStorage.getItem(LOCAL_STORAGE_CONSTANTS.ROLES));
  }, []);

  useEffect(() => {
    const logoutChannel = new BroadcastChannel('logout_channel');
    logoutChannel.addEventListener('message', handleLogoutMessage);

    return () => {
      logoutChannel.removeEventListener('message', handleLogoutMessage);
    };
  }, []);

  const handleLogoutMessage = event => {
    if (event.data === 'logout' || event.data === 'relogin') {
      setIsManualChgPermission(false);
    } else {
      setIsManualChgPermission(true);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const permissions = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.PERMISSIONS);
      const roles = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.ROLES);
      if (
        (isManualChgPermission && loginPermissions !== '' && loginPermissions !== permissions) ||
        (loginRoles !== '' && loginRoles !== roles)
      ) {
        Promise.resolve()
          .then(() => {
            localStorage.clear();
            window.location.href = `/${demoPagesMenu.login.path}`;
          })
          .then(() => {
            sessionStorage.setItem('IsKicked', 'true');
          });
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [loginPermissions, loginRoles, isManualChgPermission]);

  return (
    <Header>
      <HeaderLeft>
        {/* <Breadcrumb list={breadcrumbList} /> */}
        <></>
      </HeaderLeft>

      <HeaderRight>
        <HeaderRightContent />
      </HeaderRight>
    </Header>
  );
};

export default DefaultHeader;
