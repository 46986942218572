import { useEffect, useState } from 'react';

type ImageType = 'favicon' | 'logo';

const useGetLogo = (imageType: ImageType): string => {
  const logoPackage: string | null = localStorage.getItem('logoPackage') || '';
  const bucketName: string | null = localStorage.getItem('bucketName') || '';

  const S3_BUCKET_URL_PORTAL = `https://${bucketName}.s3.ap-southeast-1.amazonaws.com/logoPackages/portal`;

  switch (imageType) {
    case 'favicon':
      return checkFavicon(logoPackage, S3_BUCKET_URL_PORTAL);
    case 'logo':
      return checkLogo(logoPackage, S3_BUCKET_URL_PORTAL);
    default:
      return '';
  }
};

const checkFavicon = (logoPackage: string, S3_BUCKET_URL_PORTAL: string): string => {
  const checkExist = async (path: string): Promise<string> => {
    const img = new Image();
    img.src = path;
    return new Promise(resolve => {
      img.onload = () => resolve(path);
      img.onerror = () => resolve('../../../content/logoPacks/Default/favicon.ico');
    });
  };

  const [faviconPath, setFaviconPath] = useState<string>('');

  useEffect(() => {
    const fetchFavicon = async () => {
      let path = `${S3_BUCKET_URL_PORTAL}/${logoPackage}/favicon.ico`;

      const resolvedPath = await checkExist(path);
      setFaviconPath(resolvedPath);
    };

    fetchFavicon();
  }, [logoPackage]);

  return faviconPath;
};

const checkLogo = (logoPackage: string, S3_BUCKET_URL_PORTAL: string): string => {
  const checkExist = async (path: string): Promise<string> => {
    const img = new Image();
    img.src = path;
    return new Promise(resolve => {
      img.onload = () => resolve(path);
      img.onerror = () => resolve('../../../content/logoPacks/Default/merchantLogo.png');
    });
  };

  const [logoPath, setLogoPath] = useState<string>('');

  useEffect(() => {
    const fetchLogo = async () => {
      let path = `${S3_BUCKET_URL_PORTAL}/${logoPackage}/merchantLogo.png`;

      const resolvedPath = await checkExist(path);
      setLogoPath(resolvedPath);
    };

    fetchLogo();
  }, [logoPackage]);

  return logoPath;
};

export default useGetLogo;
