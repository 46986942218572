import React, { SVGProps } from 'react';

const SvgCustomInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="svg-icon" {...props}>
    <path
      d="M6.99984 13.6667C10.6798 13.6667 13.6665 10.68 13.6665 7.00002C13.6665 3.32002 10.6798 0.333353 6.99984 0.333353C3.31984 0.333353 0.33317 3.32002 0.33317 7.00002C0.33317 10.68 3.31984 13.6667 6.99984 13.6667ZM6.33317 3.66669L7.6665 3.66669V5.00002L6.33317 5.00002V3.66669ZM6.33317 6.33335H7.6665L7.6665 10.3334H6.33317L6.33317 6.33335Z"
      fill="#4D69FA"
    />
  </svg>
);

export default SvgCustomInfo;
