import React, { SVGProps } from 'react';

const SvgCustomFilter = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" className="svg-icon" {...props}>
      <path
        d="M2.57031 3H21.4303L14.5003 12.817V21H9.50031V12.817L2.57031 3ZM6.43031 5L11.5003 12.183V19H12.5003V12.183L17.5703 5H6.43031Z"
        fill="black"
      />
    </svg>
  );
};

export default SvgCustomFilter;
