import { ApiRequestConfig } from 'app/type/api-type';
import { serverApi } from './api';

const MAIN_URL = '/dashboard';

export const getTotalSalesByBrand = async (refModeCode?: string, config: ApiRequestConfig = {}) => {
  const params = new URLSearchParams();
  params.append('refModeCode', refModeCode);
  const res = await serverApi.get(`${MAIN_URL}/getTotalSalesByBrand?${params.toString()}`, config);
  return res.data.data;
};

export const getJackpotPoolPrize = async (date?: string, config: ApiRequestConfig = {}) => {
  const params = new URLSearchParams();
  params.append('date', date);
  const res = await serverApi.get(`${MAIN_URL}/getJackpotPoolPrize?${params.toString()}`, config);
  return res.data.data;
};

export const getDailySummary = async (config: ApiRequestConfig = {}) => {
  const res = await serverApi.post(`${MAIN_URL}/getDailySummary`, config);
  return res.data.data;
};

// check if user has access to Jackpot
export const getUserJackpotPermission = async (config: ApiRequestConfig = {}) => {
  const res = await serverApi.get(`${MAIN_URL}/getUserJackpotPermission`, config);
  return res.data;
};
