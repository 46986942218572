import useDarkMode from 'app/hooks/useDarkMode';
import LANG, { getLangWithKey, ILang } from 'app/lang';
import { useTranslation } from 'react-i18next';
import Button, { IButtonProps } from '../bootstrap/Button';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import Spinner from '../bootstrap/Spinner';
import { useSearchParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';

const HeaderLanguage = () => {
  const { t } = useTranslation();

  const { darkModeStatus } = useDarkMode();
  const { i18n } = useTranslation();

  const [searchParams] = useSearchParams();
  const locale = searchParams.get('locale');

  const styledBtn: IButtonProps = {
    color: darkModeStatus ? 'dark' : 'light',
    hoverShadow: 'default',
    isLight: !darkModeStatus,
    size: 'lg',
  };

  const changeLanguage = (lng: ILang['key']['lng']) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    if (locale == 'th' || locale == 'en') {
      i18n.changeLanguage(locale);
    }
  }, []);

  return (
    <div className="col-auto">
      <Dropdown>
        <DropdownToggle hasIcon={false}>
          {typeof getLangWithKey(i18n.language as ILang['key']['lng'])?.icon === 'undefined' ? (
            <Button
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...styledBtn}
              className="btn-only-icon"
              aria-label="Change language"
              data-tour="lang-selector"
            >
              <Spinner isSmall inButton="onlyIcon" isGrow />
            </Button>
          ) : (
            <Button
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...styledBtn}
              icon={getLangWithKey(i18n.language as ILang['key']['lng'])?.icon}
              aria-label="Change language"
              data-tour="lang-selector"
            >
              {t(getLangWithKey(i18n.language as ILang['key']['lng'])?.text)}
            </Button>
          )}
        </DropdownToggle>
        <DropdownMenu isAlignmentEnd data-tour="lang-selector-menu">
          {Object.keys(LANG).map(i => (
            <DropdownItem key={LANG[i].lng}>
              <Button icon={LANG[i].icon} onClick={() => changeLanguage(LANG[i].lng)}>
                {t(LANG[i].text)}
              </Button>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default HeaderLanguage;
