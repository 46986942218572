import React, { SVGProps } from 'react';

const SvgCustomFileUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="svg-icon" {...props}>
    <path
      d="M8.12125 7.24622L10.125 5.23372V13.625C10.125 13.857 10.2172 14.0796 10.3813 14.2437C10.5454 14.4078 10.7679 14.5 11 14.5C11.2321 14.5 11.4546 14.4078 11.6187 14.2437C11.7828 14.0796 11.875 13.857 11.875 13.625V5.23372L13.8787 7.24622C13.9601 7.32823 14.0569 7.39333 14.1635 7.43775C14.2701 7.48217 14.3845 7.50504 14.5 7.50504C14.6155 7.50504 14.7299 7.48217 14.8365 7.43775C14.9431 7.39333 15.0399 7.32823 15.1212 7.24622C15.2033 7.16488 15.2684 7.0681 15.3128 6.96147C15.3572 6.85485 15.3801 6.74048 15.3801 6.62497C15.3801 6.50946 15.3572 6.39509 15.3128 6.28847C15.2684 6.18184 15.2033 6.08506 15.1212 6.00372L11.6212 2.50372C11.538 2.42406 11.4399 2.36162 11.3325 2.31997C11.1195 2.23245 10.8805 2.23245 10.6675 2.31997C10.5601 2.36162 10.462 2.42406 10.3787 2.50372L6.87875 6.00372C6.79717 6.0853 6.73245 6.18216 6.6883 6.28875C6.64414 6.39535 6.62142 6.50959 6.62142 6.62497C6.62142 6.74035 6.64414 6.85459 6.6883 6.96119C6.73245 7.06778 6.79717 7.16464 6.87875 7.24622C6.96033 7.3278 7.05719 7.39252 7.16378 7.43667C7.27038 7.48082 7.38462 7.50355 7.5 7.50355C7.61538 7.50355 7.72962 7.48082 7.83622 7.43667C7.94281 7.39252 8.03967 7.3278 8.12125 7.24622ZM18.875 11C18.6429 11 18.4204 11.0922 18.2563 11.2563C18.0922 11.4203 18 11.6429 18 11.875V17.125C18 17.357 17.9078 17.5796 17.7437 17.7437C17.5796 17.9078 17.3571 18 17.125 18H4.875C4.64294 18 4.42038 17.9078 4.25628 17.7437C4.09219 17.5796 4 17.357 4 17.125V11.875C4 11.6429 3.90781 11.4203 3.74372 11.2563C3.57962 11.0922 3.35706 11 3.125 11C2.89294 11 2.67038 11.0922 2.50628 11.2563C2.34219 11.4203 2.25 11.6429 2.25 11.875V17.125C2.25 17.8212 2.52656 18.4888 3.01884 18.9811C3.51113 19.4734 4.17881 19.75 4.875 19.75H17.125C17.8212 19.75 18.4889 19.4734 18.9812 18.9811C19.4734 18.4888 19.75 17.8212 19.75 17.125V11.875C19.75 11.6429 19.6578 11.4203 19.4937 11.2563C19.3296 11.0922 19.1071 11 18.875 11Z"
      fill="black"
    />
  </svg>
);

export default SvgCustomFileUpload;
