import React, { SVGProps } from 'react';

const SvgCustomThailand = (props: SVGProps<SVGSVGElement>) => (
  <svg
  	width="800px"
	height="800px"
	viewBox="0 0 36 36"
	xmlns="http://www.w3.org/2000/svg"
	aria-hidden="true"
	role="img"
  	preserveAspectRatio="xMidYMid meet"
    {...props}>
    <path
    	style={{
			fill: '#A7122D',
		}}
      d='M0 26.518V27a4 4 0 0 0 4 4h28a4 4 0 0 0 4-4v-.482H0z'
    />
    <path
    	style={{
			fill: '#EEE',
		}}
      d='M0 22.181h36v4.485H0z'
    />
    <path
    	style={{
			fill: '#292648',
		}}
      d='M0 13.513h36v8.821H0z'
    />
    <path
    	style={{
			fill: '#EEE',
		}}
      d='M0 9.181h36v4.485H0z'
    />
    <path
    	style={{
			fill: '#A7122D',
		}}
      d='M0 9.333V9a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4v.333H0z'
    />
  </svg>
);

export default SvgCustomThailand;
