import { useMutation } from '@tanstack/react-query';
import { demoPagesMenu } from 'app/menu';
import { postLogout } from 'app/services/authApi';

const useLogout = () => {
  const broadcastLogout = () => {
    const logoutChannel = new BroadcastChannel('logout_channel');
    logoutChannel.postMessage('logout');
  };

  const postLogoutMutation = useMutation({
    mutationFn: () => postLogout({}),
    onSuccess: ({ data }) => {
      broadcastLogout();
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = `/${demoPagesMenu.login.path}`;
    },
    onError: err => {
      broadcastLogout();
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = `/${demoPagesMenu.login.path}`;
    },
  });

  return { postLogoutMutation };
};

export default useLogout;
