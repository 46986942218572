import { LOCAL_STORAGE_CONSTANTS } from 'app/common/localStorageConstants';
import AuthContext from 'app/contexts/authContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const HeaderUser = () => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const roles = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.ROLES);

  return (
    <div className="col-auto">
      <div className="user-info">
        <div className="user-name d-flex align-items-center fs-5 text-primary text-capitalize justify-content-end fw-bold">{user}</div>
        <div className="fs-6 text-capitalize">{t(roles)}</div>
      </div>
    </div>
  );
};

export default HeaderUser;
