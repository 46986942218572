import { Modal } from '@mantine/core';
import React from 'react';
import Button from '../bootstrap/Button';
import Icon from '../icon/Icon';
import { useTranslation } from 'react-i18next';

interface IModalConfirmProps {
  modalTitle: string;
  modalBody: string;
  modalField?: any;
  opened: boolean;
  close: () => void;
  onConfirmClick: () => void;
  confirmTitle?: string;
  disabled?: boolean;
  zIndex?: number;
}
const ModalConfirm = ({
  modalTitle,
  modalBody,
  modalField,
  opened,
  close,
  onConfirmClick,
  confirmTitle,
  disabled,
  zIndex,
}: IModalConfirmProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      styles={theme => ({
        content: {
          borderTop: '4px solid',
          borderTopColor: theme.colors.red[5],
        },
      })}
      opened={opened}
      onClose={close}
      title={modalTitle}
      zIndex={zIndex}
    >
      <div className="d-flex gap-3 mb-4 align-items-center">
        <Icon icon="Error" color="danger" size="5x" />
        <p className="m-0">{modalBody}</p>
      </div>
      <div>{modalField}</div>
      <div className="d-flex gap-4 w-100">
        <Button className="w-100" style={{ borderColor: 'blue' }} onClick={close} isDisable={disabled}>
          {t('cancel')}
        </Button>
        <Button icon="Cancel" color="danger" className="w-100" onClick={onConfirmClick} isDisable={disabled}>
          {confirmTitle}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalConfirm;
