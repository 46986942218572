import React, { SVGProps } from 'react';

const SvgCustomRevert = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00065 13.3334C9.48954 13.3334 10.7507 12.8168 11.784 11.7834C12.8173 10.7501 13.334 9.48897 13.334 8.00008C13.334 6.5112 12.8173 5.25008 11.784 4.21675C10.7507 3.18342 9.48954 2.66675 8.00065 2.66675C7.23398 2.66675 6.50065 2.82497 5.80065 3.14142C5.10065 3.45786 4.50065 3.91075 4.00065 4.50008V3.33342C4.00065 3.14453 3.93665 2.98608 3.80865 2.85808C3.68065 2.73008 3.52243 2.66631 3.33398 2.66675C3.14509 2.66675 2.98665 2.73075 2.85865 2.85875C2.73065 2.98675 2.66687 3.14497 2.66732 3.33342V6.66675C2.66732 6.85564 2.73132 7.01408 2.85932 7.14208C2.98732 7.27008 3.14554 7.33386 3.33398 7.33342H6.66732C6.85621 7.33342 7.01465 7.26942 7.14265 7.14142C7.27065 7.01342 7.33443 6.8552 7.33398 6.66675C7.33398 6.47786 7.26998 6.31942 7.14198 6.19142C7.01398 6.06342 6.85576 5.99964 6.66732 6.00008H4.53398C4.88954 5.37786 5.37576 4.88897 5.99265 4.53342C6.60954 4.17786 7.27887 4.00008 8.00065 4.00008C9.11176 4.00008 10.0562 4.38897 10.834 5.16675C11.6118 5.94453 12.0007 6.88897 12.0007 8.00008C12.0007 9.1112 11.6118 10.0556 10.834 10.8334C10.0562 11.6112 9.11176 12.0001 8.00065 12.0001C7.2451 12.0001 6.55332 11.8083 5.92532 11.4248C5.29732 11.0412 4.81132 10.5274 4.46732 9.88342C4.37843 9.72786 4.25332 9.61964 4.09198 9.55875C3.93065 9.49786 3.76687 9.49497 3.60065 9.55008C3.42287 9.60564 3.2951 9.72231 3.21732 9.90008C3.13954 10.0779 3.1451 10.2445 3.23398 10.4001C3.68954 11.289 4.33954 12.0001 5.18398 12.5334C6.02843 13.0668 6.96732 13.3334 8.00065 13.3334Z"
        fill="black"
      />
    </svg>
  );
};

export default SvgCustomRevert;
