import { useDisclosure } from '@mantine/hooks';
import useDarkMode from 'app/hooks/useDarkMode';
import useLogout from 'app/hooks/useLogout';
import React, { useEffect } from 'react';
import Button, { IButtonProps } from '../bootstrap/Button';
import Popovers from '../bootstrap/Popovers';
import ModalConfirm from '../modal/ModalConfirm';
import { useTranslation } from 'react-i18next';

const HeaderLogout = () => {
  const { darkModeStatus } = useDarkMode();
  const [opened, { open, close }] = useDisclosure(false);
  const styledBtn: IButtonProps = {
    color: darkModeStatus ? 'dark' : 'light',
    size: 'lg',
  };
  const { postLogoutMutation } = useLogout();
  const { t } = useTranslation();

  const onLogoutClick = () => {
    open();
  };
  const onConfirmLogout = () => {
    postLogoutMutation.mutate();
  };

  useEffect(() => {
    if (opened) {
      sessionStorage.setItem('isLogoutModalOpen', 'true');
    } else {
      sessionStorage.setItem('isLogoutModalOpen', 'false');
    }
  }, [opened]);

  return (
    <div className="col-auto">
      <Popovers trigger="hover" desc="Log out">
        <Button {...styledBtn} icon="Logout" onClick={onLogoutClick} />
      </Popovers>
      <ModalConfirm
        modalTitle={t('confirmation')}
        modalBody={t('areYouSureLogout')}
        opened={opened}
        close={close}
        onConfirmClick={onConfirmLogout}
        confirmTitle={t('logout')}
        disabled={postLogoutMutation.isLoading}
      />
    </div>
  );
};

export default HeaderLogout;
